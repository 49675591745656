import React from 'react';
import { Container as MuiContainer, Grid, Link, styled, Typography, useTheme } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { Header } from '../Header/Header';
import { Picture } from '../Picture';
import { CashbackNumber } from './CashbackNumber';

const Container = styled(MuiContainer)(({ theme }) => ({
  marginTop: theme.spacing(30),
  marginBottom: theme.spacing(27),

  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(21),
    marginBottom: theme.spacing(21),
  },
}));

const TitleContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(29),
  textAlign: 'center',

  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(14),
  },
}));

const Card = styled(Grid)(({ theme }) => ({
  ['&:not(:last-child)']: {
    marginBottom: theme.spacing(39),
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    gap: theme.spacing(9),

    ['&:not(:last-child)']: {
      marginBottom: theme.spacing(8),
    },
  },
}));

const CardImageGrid = styled(Grid)(({ theme }) => ({
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    order: -1,
    marginBottom: theme.spacing(8),
  },
}));

export const FundDiscount = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-sectionid="funds-discount-description">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <TitleContainer>
            <Header
              variant="h2"
              id="fund-discount"
              headerText={t('redesignCashbackPage.fundDiscount.title')}
              sx={{ marginBottom: theme.spacing(4) }}
            />
            <Typography variant="subtitle2" color="textSecondary">
              {t('redesignCashbackPage.fundDiscount.description')}
            </Typography>
          </TitleContainer>
        </Grid>
      </Grid>

      <Card container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={5}>
          <Header
            variant="h3"
            headerText={t('redesignCashbackPage.fundDiscount.oneStep.title')}
            sx={{
              marginBottom: theme.spacing(5),
            }}
          />
          <Typography variant="subtitle2" color="textSecondary">
            <Trans
              i18nKey="redesignCashbackPage.fundDiscount.oneStep.description"
              components={{
                separator: <br />,
                underline: <u />,
                calculatorRef: (
                  <Link
                    href="components/FundDiscount/FundDiscount#detail-calculator"
                    underline="hover"
                  />
                ),
              }}
            />
          </Typography>
        </Grid>
        <CardImageGrid item xs={12} md={6}>
          <Picture
            baseImagePath="/images/redesign/cashback/Fondsdiscount-Ersparnis"
            title={t('redesignCashbackPage.fundDiscount.oneStep.imageTitle')}
            enableWebP={false}
            resolutions={[1, 2, 3]}
            width="100%"
          />
        </CardImageGrid>
      </Card>

      <Card container alignItems="center" justifyContent="space-between">
        <CardImageGrid item xs={12} md={5}>
          <CashbackNumber />
          <Picture
            baseImagePath="/images/redesign/cashback/Fondsdiscount-Auszahlung"
            title={t('redesignCashbackPage.fundDiscount.custodianBank.imageTitle')}
            enableWebP={false}
            resolutions={[1, 2]}
            width="100%"
          />
        </CardImageGrid>
        <Grid item xs={12} md={5}>
          <Header
            variant="h3"
            headerText={t('redesignCashbackPage.fundDiscount.custodianBank.title')}
            sx={{
              marginBottom: theme.spacing(5),
            }}
          />
          <Typography variant="subtitle2" color="textSecondary">
            <Trans
              i18nKey="redesignCashbackPage.fundDiscount.custodianBank.description"
              components={{ separator: <br /> }}
            />
          </Typography>
        </Grid>
      </Card>

      <Card container alignItems="center" justifyContent="space-between">
        <Grid item xs={12} md={5}>
          <Header
            variant="h3"
            headerText={t('redesignCashbackPage.fundDiscount.applyingCashback.title.firstLine')}
            subHeaderText={t('redesignCashbackPage.fundDiscount.applyingCashback.title.secondLine')}
            sx={{
              marginBottom: theme.spacing(5),
            }}
          />
          <Typography variant="subtitle2" color="textSecondary">
            <Trans
              i18nKey="redesignCashbackPage.fundDiscount.applyingCashback.description"
              components={{ separator: <br />, underline: <u /> }}
            />
          </Typography>
        </Grid>
        <CardImageGrid item xs={12} md={5}>
          <Picture
            baseImagePath="/images/redesign/cashback/Fondsdiscount-Mehrertrag"
            title={t('redesignCashbackPage.fundDiscount.applyingCashback.imageTitle')}
            enableWebP={false}
            resolutions={[1, 2]}
            width="100%"
          />
        </CardImageGrid>
      </Card>
    </Container>
  );
};
