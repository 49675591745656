import React from 'react';
import { Box, Divider, styled, useTheme } from '@mui/material';
import { CashbackCalculator } from '../cashback/redesign/CashbackCalculator';
import { WellKnown } from '../../components/WellKnown/WellKnown';
import { FundDiscount } from '../../components/FundDiscount/FundDiscount';
import { Conditions } from '../cashback/redesign/Conditions';
import { Testimonials } from '../cashback/redesign/Testimonials';
import { CashbackStepper } from '../cashback/redesign/CashbackStepper/CashbackStepper';
import { FaqExpansionPanels } from '../cashback/redesign/FaqExpansionPanels';
import { Questions } from '../cashback/redesign/Questions/Questions';
import { useGoogleTagManagerViewHandler } from '../../src/hooks/useGoogleTagManagerViewHandler';
import { USER_FLOW_ID } from '../../src/helpers/constants';
import { MainScreenWithVideo } from './MainScreenWithVideo';
import { Description } from './Description';
import { Experienced } from './Experienced/Experienced';
import { Advantages } from './Advantages/Advantages';
import { AdvantagesChecklist } from './AdvantagesChecklist';
import { Counters } from './Counters/Counters';

const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.border?.light,
}));

export const Content = () => {
  const theme = useTheme();

  useGoogleTagManagerViewHandler(USER_FLOW_ID);

  return (
    <>
      <MainScreenWithVideo />
      <CashbackCalculator />
      <Box marginTop={theme.spacing(11)} marginBottom={theme.spacing(34)}>
        <WellKnown />
      </Box>
      <DividerStyled />
      <FundDiscount />
      <DividerStyled />
      <Advantages />
      <AdvantagesChecklist />
      <Experienced />
      <Description />
      <Conditions />
      <Testimonials />
      <CashbackStepper />
      <DividerStyled />
      <FaqExpansionPanels />
      <Questions />
      <Counters />
    </>
  );
};
