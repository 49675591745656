import { ReactNode } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

type AdvantageCardProps = {
  header: string;
  text: string;
  icon: ReactNode;
};

const CardInnerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.neutralLight,
  padding: theme.spacing(6, 7),
  height: '100%',
  borderRadius: '8px',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 5),
  },
}));

const IconContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(2)};
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 6px -1.5px rgba(30, 39, 50, 0.2);
  width: 44px;
  height: 44px;
  margin-bottom: ${({ theme }) => theme.spacing(4)};

  & svg {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled(Typography)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const AdvantageCard = ({ header, text, icon }: AdvantageCardProps) => {
  return (
    <Grid item xs={12} sm={6} md={4}>
      <CardInnerContainer>
        <IconContainer>{icon}</IconContainer>
        <Title variant="subtitle1" color="common.O400">
          {header}
        </Title>
        <Typography variant="textLight" color="textSecondary">
          {text}
        </Typography>
      </CardInnerContainer>
    </Grid>
  );
};
