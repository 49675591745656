import { ReactElement } from 'react';
import { Box, BoxProps, Grid, styled } from '@mui/material';

const GridContainer = styled(Grid)(({ theme }) => ({
  flexWrap: 'nowrap',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    gap: theme.spacing(10),
    flexDirection: 'column',
  },
}));

const Section = styled(Box)(({ theme }) => ({
  width: '50%',

  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

interface TwoSectionsContainerProps extends BoxProps {
  children: [ReactElement, ReactElement];
}

export const TwoSectionsContainer = ({ children, ...rest }: TwoSectionsContainerProps) => {
  return (
    <Box width="100%" {...rest}>
      <GridContainer container>
        <Section>{children[0]}</Section>
        <Section>{children[1]}</Section>
      </GridContainer>
    </Box>
  );
};
