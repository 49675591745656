import { Box, Container, Grid, styled, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Header } from '../../../components/Header/Header';
import RentabloLogo from '../../../public/images/rentablo-logo.svg';

const Wrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.tertiary,
  backgroundImage: `url('/images/blue-background-large.svg')`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right center',
  padding: theme.spacing(7, 8, 12, 8),
  borderRadius: 8,

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(7, 8),
    backgroundPosition: 'right top',
    backgroundImage: `url('/images/blue-background.svg')`,
  },
}));

const Logo = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const InBetweenContainer = styled(Box)({
  position: 'absolute',
  width: '100%',
  transform: 'translateY(-50%)',
});

export const Experienced = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container>
      <Box position="relative">
        <InBetweenContainer>
          <Grid container>
            <Grid item xs={12}>
              <Wrapper>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Logo>
                      <RentabloLogo
                        width="105px"
                        height="38px"
                        alt={t('redesignMainPage.experienced.logoAlt')}
                      />
                    </Logo>
                    <Header
                      variant="h4"
                      headerText={t('redesignMainPage.experienced.title')}
                      color="common.M500"
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                    />
                    <Typography variant="subtitle2">
                      {t('redesignMainPage.experienced.subtitle')}
                    </Typography>
                  </Grid>
                </Grid>
              </Wrapper>
            </Grid>
          </Grid>
        </InBetweenContainer>
      </Box>
    </Container>
  );
};
