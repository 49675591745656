import React from 'react';
import { styled, Container } from '@mui/material';
import { OneForAll } from './OneForAll';

const Wrapper = styled('div')(({ theme }) => ({
  background: theme.palette.grey[800],
  padding: theme.spacing(66, 0, 14),

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(49, 0, 14),
  },
}));

export const Description = () => {
  return (
    <Wrapper>
      <Container data-sectionid="finance-manager-description">
        <OneForAll />
      </Container>
    </Wrapper>
  );
};
