import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { styled, Button, Link, Typography, useTheme, ButtonProps } from '@mui/material';
import { MouseEvent } from 'react';

const Actions = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: theme.spacing(6),
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(6),

  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },

  '& .MuiLink-root:hover': {
    color: theme.palette.grey[700],
    textDecoration: 'underline',
  },
}));

const MoreButtonText = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  cursor: 'pointer',
}));

type ButtonWithLinkProps = ButtonProps & {
  buttonHref: ((e: MouseEvent<HTMLButtonElement>) => void) | string;
  linkHref: ((e: MouseEvent<HTMLAnchorElement>) => void) | string;
  dataTestId?: string;
  buttonText: string;
  linkText: string;
};

export const ButtonWithLink = ({
  buttonText,
  linkText,
  buttonHref,
  linkHref,
  ...rest
}: ButtonWithLinkProps) => {
  const isButtonHrefValid = typeof buttonHref === 'string';
  const isLinkHrefValid = typeof linkHref === 'string';
  const theme = useTheme();

  return (
    <Actions>
      <Button
        href={isButtonHrefValid ? buttonHref : undefined}
        onClick={isButtonHrefValid ? undefined : buttonHref}
        variant="contained"
        color="primary"
        sx={{ [theme.breakpoints.down('sm')]: { width: '100%' } }}
        {...rest}
      >
        {buttonText}
      </Button>
      <Link
        href={isLinkHrefValid ? linkHref : undefined}
        onClick={isLinkHrefValid ? undefined : linkHref}
      >
        <MoreButtonText variant="body1" color="textPrimary">
          {linkText}
          <ArrowForwardIcon fontSize="inherit" />
        </MoreButtonText>
      </Link>
    </Actions>
  );
};
