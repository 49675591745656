import { styled, Typography } from '@mui/material';
import Checkmark from '../../public/icons/checkmark.svg';

type CheckmarkListProps = {
  list: string[];
};

const CheckmarkListStyled = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const CheckmarkListItem = styled('li')`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.positive};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  & svg {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const CheckmarkList = ({ list }: CheckmarkListProps) => {
  return (
    <CheckmarkListStyled>
      {list.map((listItem) => (
        <CheckmarkListItem key={listItem}>
          <Checkmark />
          <Typography>{listItem}</Typography>
        </CheckmarkListItem>
      ))}
    </CheckmarkListStyled>
  );
};
