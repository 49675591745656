interface PictureProps {
  baseImagePath: string;
  width?: string;
  title: string;
  className?: string;
  enableWebP?: boolean;
  resolutions?: [1] | [1, 2] | [1, 2, 3];
}

const resolutionMap = {
  1: '',
  2: '2x',
  3: '3x',
};

const getResolutionString = (
  baseImagePath: string,
  resolution: 1 | 2 | 3,
  format: 'png' | 'webp'
) => {
  return `${baseImagePath}${resolution === 1 ? '' : '@'}${resolutionMap[resolution]}.${format} ${
    resolutionMap[resolution]
  }`;
};

export const Picture = ({
  baseImagePath,
  width,
  className,
  title,
  enableWebP = true,
  resolutions = [1, 2, 3],
}: PictureProps) => {
  const webPResolutions = resolutions.map((resolution) =>
    getResolutionString(baseImagePath, resolution, 'webp')
  );
  const pngResolutions = resolutions.map((resolution) =>
    getResolutionString(baseImagePath, resolution, 'png')
  );

  return (
    <picture className={className}>
      {enableWebP && <source type="image/webp" srcSet={webPResolutions.join(', ')} />}
      <source type="image/jpeg" srcSet={pngResolutions.join(', ')} />
      <img
        width={width}
        srcSet={pngResolutions.join(', ')}
        src={`${baseImagePath}.png`}
        loading="lazy"
        className="lazyload"
        alt={title}
        title={title}
      />
    </picture>
  );
};
