import React, { ReactNode } from 'react';
import NextLink from 'next/link';
import { Link as MaterialLink, TypographyProps } from '@mui/material';

interface Props {
  href: string;
  children: ReactNode;
  className?: string;
  underline?: 'none' | 'hover' | 'always';
  variant?: TypographyProps['variant'] | 'inherit';
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
}

export const Link = ({ children, className, href, underline, variant, color }: Props) => (
  <NextLink href={href}>
    <MaterialLink
      href={href}
      className={className}
      underline={underline}
      variant={variant}
      color={color}
    >
      {children}
    </MaterialLink>
  </NextLink>
);
