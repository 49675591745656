import React, { useState } from 'react';
import {
  Typography,
  Container,
  styled,
  Grid,
  Box,
  MobileStepper,
  IconButton,
  useTheme,
} from '@mui/material';
import chunk from 'lodash/chunk';
import { useTranslation } from 'react-i18next';
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Carousel } from 'react-responsive-carousel';
import i18n from '../../../components/i18n';
import { Header } from '../../../components/Header/Header';

const CarouselStyled = styled(Carousel)({
  '& .slide': {
    background: 'transparent',
  },
});

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  width: 36,
  height: 36,
  border: `1px solid ${theme.palette.border?.main}`,
  borderRadius: 24,
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  marginBottom: theme.spacing(43),

  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(21.5),
  },
}));

const MobileStepperStyled = styled(MobileStepper)(({ theme }) => ({
  '& .MuiMobileStepper-dot': {
    width: 6,
    height: 6,
    background: theme.palette.grey[200],
    margin: '0 3px',
  },

  '& .MuiMobileStepper-dotActive': {
    background: theme.palette.tertiary.main,
  },
}));

const CardContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: theme.spacing(4),
  padding: theme.spacing(5, 8),
  background: theme.palette.background.neutralLight,
  width: '100%',
  minHeight: 256,
  borderRadius: 8,
  backgroundImage: 'url("/images/redesign/cashback/background-waves.svg")',
  backgroundPosition: 'left top',
  backgroundRepeat: 'no-repeat',
}));

const CardMeta = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(4),
}));

const CardMetaImage = styled('img')({
  width: '56px !important',
  height: '56px !important',
  borderRadius: '50%',
});

const CardText = styled(Typography)({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '26px',
  textAlign: 'left',
});

const TestimonialMap: Record<string, CardProps> = {
  vincentWillkomm: {
    imgPath: '/images/redesign/cashback/testimonials/willkomm.png',
    title: i18n.t('redesignCashbackPage.testimonials.cards.vincentWillkomm.name'),
    text: i18n.t('redesignCashbackPage.testimonials.cards.vincentWillkomm.text'),
    profession: i18n.t('redesignCashbackPage.testimonials.cards.vincentWillkomm.profession'),
  },
  ingeborgK: {
    imgPath: '/images/redesign/cashback/testimonials/ingeborg.png',
    title: i18n.t('redesignCashbackPage.testimonials.cards.ingeborgK.name'),
    text: i18n.t('redesignCashbackPage.testimonials.cards.ingeborgK.text'),
    profession: i18n.t('redesignCashbackPage.testimonials.cards.ingeborgK.profession'),
  },
  konetzke: {
    imgPath: '/images/redesign/cashback/testimonials/konetzke.png',
    title: i18n.t('redesignCashbackPage.testimonials.cards.konetzke.name'),
    text: i18n.t('redesignCashbackPage.testimonials.cards.konetzke.text'),
    profession: i18n.t('redesignCashbackPage.testimonials.cards.konetzke.profession'),
  },
  siebers: {
    imgPath: '/images/redesign/cashback/testimonials/siebers.png',
    title: i18n.t('redesignCashbackPage.testimonials.cards.siebers.name'),
    text: i18n.t('redesignCashbackPage.testimonials.cards.siebers.text'),
    profession: i18n.t('redesignCashbackPage.testimonials.cards.siebers.profession'),
  },
};

const testimonialSources = chunk(Object.keys(TestimonialMap), 2);

interface CardProps {
  imgPath: string;
  title: string;
  profession: string;
  text: string;
}

export const Testimonials = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNextClick = () => setCurrentSlide((state) => state + 1);
  const handlePreviousClick = () => setCurrentSlide((state) => state - 1);
  const handleChangeCarousel = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };
  return (
    <ContainerStyled data-sectionid="testimonials">
      <Header
        variant="h2"
        headerText={t('redesignCashbackPage.testimonials.title')}
        subHeaderText={t('redesignCashbackPage.testimonials.subtitle')}
        sx={{
          marginBottom: theme.spacing(11),
        }}
      />
      <CarouselStyled
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        selectedItem={currentSlide}
        autoPlay
        infiniteLoop
        interval={5000}
        onChange={handleChangeCarousel}
      >
        {testimonialSources.map((testimonialSource) => (
          <Grid container spacing={4} key={testimonialSource[0]}>
            {testimonialSource.map((source) => (
              <Grid item xs={12} md={6} key={source}>
                <CardContainer>
                  <CardText>{TestimonialMap[source].text}</CardText>
                  <CardMeta>
                    <CardMetaImage src={TestimonialMap[source].imgPath} />
                    <div>
                      <Typography variant="textBold" color="textPrimary" align="left">
                        {TestimonialMap[source].title}
                      </Typography>
                      <Typography variant="text" color="textSecondary" align="left">
                        {TestimonialMap[source].profession}
                      </Typography>
                    </div>
                  </CardMeta>
                </CardContainer>
              </Grid>
            ))}
          </Grid>
        ))}
      </CarouselStyled>
      {testimonialSources.length > 1 && (
        <Box mt={8}>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={6} md={4}>
              <MobileStepperStyled
                variant="dots"
                position="static"
                activeStep={currentSlide}
                steps={testimonialSources.length}
                backButton={
                  <IconButtonStyled onClick={handlePreviousClick}>
                    <ChevronLeftRoundedIcon />
                  </IconButtonStyled>
                }
                nextButton={
                  <IconButtonStyled onClick={handleNextClick}>
                    <ChevronRightRoundedIcon />
                  </IconButtonStyled>
                }
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </ContainerStyled>
  );
};
