export const HOME = '/';
export const CASHBACK_WIZARD_START = '/v2/cashback-wizard-start';
export const YOUR_DISCOUNT = '#your-discount';
export const CASHBACK_CALCULATOR = '#cashback-calculator';
export const FAQS = '#faq';
export const FORUM = 'https://forum.rentablo.de/';
export const BLOG = 'https://blog.rentablo.de/';
export const FEE_BASED_ADVICE = '/advice';
export const FINANCE_MANAGER_DASHBOARD = '/v2';
export const CASHBACK_LP = '/cashback';
export const PORTFOLIO_MANAGER_LP = '/software-depotverwaltung';
export const ABOUT_US = 'https://wealthapi.eu/ueber-uns/';
export const LOGIN = '/v2/login';
export const LOGOUT = '/v2/logout';
export const SIGNUP = '/v2/signup';
export const FINANCE_MANAGER_MANAGE_MY_BOOKINGS = '/app#!/cashbackSubscriptionDashboard';
export const PARTNER_BANKS_BASE = '/cashback';
export const ONVISTA_HOME = 'https://onvista.de';
export const FUND_COMMISSION_CALCULATOR = '/fonds-provisionsrechner';
