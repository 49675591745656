import { useTranslation } from 'react-i18next';
import { Box, Button, Container, useTheme } from '@mui/material';
import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Header } from '../../components/Header/Header';
import { AdvantagesTable } from '../../components/AdvantagesTable/AdvantagesTable';
import { CASHBACK_WIZARD_START } from '../../src/helpers/urlConstants';

export const AdvantagesChecklist = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-sectionid="advantages-checklist">
      <Header
        align="center"
        variant="h3"
        headerText={t('landingMain.yourAdvantages.checklist.title')}
        sx={{
          marginBottom: theme.spacing(11),
        }}
      />
      <Box
        sx={{
          marginBottom: theme.spacing(22),
          [theme.breakpoints.down('md')]: { marginBottom: theme.spacing(11) },
        }}
      >
        <AdvantagesTable />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        sx={{
          marginBottom: theme.spacing(62.5),
          [theme.breakpoints.down('md')]: { marginBottom: theme.spacing(45) },
        }}
      >
        <Button
          href={CASHBACK_WIZARD_START}
          color="primary"
          size="medium"
          variant="contained"
          endIcon={<ArrowForwardIcon fontSize="inherit" />}
          sx={{ [theme.breakpoints.down('sm')]: { width: '100%' } }}
          data-eventid="book-cashback-button"
        >
          {t('landingMain.yourAdvantages.checklist.button')}
        </Button>
      </Box>
    </Container>
  );
};
