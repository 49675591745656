import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import RentabloLogo from '../../public/images/rentablo-logo.svg';
import YesIcon from './icons/check.svg';
import NoIcon from './icons/close.svg';

const tableData = [
  { title: 'Agio bis zu 100%', 1.0: true, 2.0: true },
  { title: 'Cashback bis zu 100%', 1.0: true, 2.0: false },
  { title: 'Begrenzung des Provisionseinbehalts', 1.0: true, 2.0: false },
  { title: 'Transparente Abrechnungen quartalsweise', 1.0: true, 2.0: false },
  { title: 'Sicherheit durch BaFin-Regulierung', 1.0: true, 2.0: false },
  { title: 'Integrierter Steuerhelfer', 1.0: true, 2.0: false },
];

const AdvantagesTableContainer = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  border: `2px solid ${theme.palette.border?.light}`,
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '16px',
  lineHeight: '24px',
  color: theme.palette.text.primary,

  '& .MuiGrid-item': {
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1.75, 0),
    },
  },

  '& .MuiGrid-item:first-of-type': {
    padding: theme.spacing(4, 0, 4, 5),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 0, 3, 2.5),
      fontSize: '14px',
      fontWeight: 500,
    },
  },
}));

const RowsContainer = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.border?.light}`,

  '&:last-of-type': {
    borderBottom: 'none',
  },

  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.background.neutralLight,
  },
}));

const HeaderCell = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '80px',
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${theme.palette.border?.light}`,
  padding: `${theme.spacing(0, 0, 0, 5)} !important`,

  [theme.breakpoints.down('md')]: {
    paddingLeft: `${theme.spacing(2.5)} !important`,
    height: '58px',
  },
}));

const HeaderIconCell = styled(HeaderCell)(({ theme }) => ({
  justifyContent: 'center',
  flexDirection: 'column',
  padding: `0 !important`,
  fontSize: '15px',
  color: theme.palette.text.secondary,
  fontWeight: 500,

  '& svg': {
    marginBottom: theme.spacing(1),
  },

  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    lineHeight: '20px',
    padding: `0 !important`,

    '& svg': {
      marginBottom: 0,
    },
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

const HeaderAdvantageCell = styled(HeaderCell)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '20px',
  },

  [theme.breakpoints.down('sm')]: {
    fontSize: '10px',
  },
}));

const StyledRentabloLogo = styled(RentabloLogo)(({ theme }) => ({
  width: '88px',
  height: '32px',

  [theme.breakpoints.down('md')]: {
    width: '53px',
    height: '20px',
  },
}));

export const AdvantagesTable = () => {
  const { t } = useTranslation();

  return (
    <AdvantagesTableContainer>
      <Grid container>
        <HeaderCell item md={4} sm={6} xs={4}>
          {t('components.advantagesTable.headers.first')}
        </HeaderCell>
        <HeaderIconCell item md={4} sm={3} xs={4}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="center"
            flexDirection="column"
          >
            <StyledRentabloLogo />
            {t('components.advantagesTable.headers.second')}
          </Box>
        </HeaderIconCell>
        <HeaderAdvantageCell item md={4} sm={3} xs={4} display="flex" justifyContent="center">
          {t('components.advantagesTable.headers.third')}
        </HeaderAdvantageCell>
      </Grid>
      {tableData.map((tableRow) => (
        <RowsContainer container key={tableRow.title}>
          <Grid item md={4} sm={6} xs={6}>
            {tableRow.title}
          </Grid>
          <Grid item md={4} sm={3} xs={3} display="flex" justifyContent="center">
            {tableRow[1.0] ? (
              <YesIcon width="32px" height="32px" />
            ) : (
              <NoIcon width="32px" height="32px" />
            )}
          </Grid>
          <Grid item md={4} sm={3} xs={3} display="flex" justifyContent="center">
            {tableRow[2.0] ? (
              <YesIcon width="32px" height="32px" />
            ) : (
              <NoIcon width="32px" height="32px" />
            )}
          </Grid>
        </RowsContainer>
      ))}

      {/*    <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('components.advantagesTable.headers.first')}</TableCell>
              <TableCell>{t('components.advantagesTable.headers.second')}</TableCell>
              <TableCell>{t('components.advantagesTable.headers.third')}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>*/}
    </AdvantagesTableContainer>
  );
};
