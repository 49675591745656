import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';

const Container = styled('div')(({ theme }) => ({
  backgroundColor: '#ffd4d4',
  padding: theme.spacing(3),
  width: '100%',
}));

export const IEAlert = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <div style={{ width: '100%' }}>
        <div>
          <strong>{t('ieAlert.title')}</strong>
        </div>
        <div style={{ width: '100%' }}>{t('ieAlert.description')}</div>
      </div>
    </Container>
  );
};
