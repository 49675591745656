import React, { useContext } from 'react';
import { Box, Container, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CASHBACK_WIZARD_START } from '../../../src/helpers/urlConstants';
import { Header } from '../../../components/Header/Header';
import { CashbackCalculatorContext } from '../../../components/CashbackCalculatorProvider';
import { Fund } from '../../../api/useFunds';
import { useGoogleTagManager } from '../../../src/hooks/useGoogleTagManager';
import { USER_FLOW_ID } from '../../../src/helpers/constants';
import DetailCalculatorWithUrlChange from '../../cashbackCalculator/DetailCalculatorWithUrlChange';

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(11),
  fontSize: 14,

  [theme.breakpoints.down('sm')]: {
    gap: theme.spacing(9),
  },
}));

const CalculatorBox = styled('div')(({ theme }) => ({
  border: 'solid 1px',
  borderRadius: '8px',
  borderColor: theme.palette.border?.main,

  [theme.breakpoints.down('sm')]: {
    boxShadow: '0px 2px 4px -1px rgba(30, 39, 50, 0.16)',
  },
}));

export const CashbackCalculator = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const ref = useContext(CashbackCalculatorContext);
  const isLessSm = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const sendToGoogleTagManager = useGoogleTagManager(USER_FLOW_ID);

  const onFundSelect = (selectedFund: Fund) => {
    if (!ref?.current?.fundsMap?.some((fund: Fund) => fund.isin === selectedFund.isin)) {
      sendToGoogleTagManager({
        contextId: 'cashback-calculator',
        event: 'fund-selected',
        contentElementId: selectedFund.isin,
      });
    }
  };

  return (
    <Container data-sectionid="cashback-calculator">
      <Wrapper>
        <Box>
          <Header
            variant="h3"
            headerText={t('landingMain.cashbackCalculator.title')}
            subHeaderText={t('landingMain.cashbackCalculator.subTitle')}
            id="cashback-calculator"
            sx={{
              marginBottom: theme.spacing(isLessSm ? 4 : 8),
            }}
          />
          <Typography variant="subtitle2" sx={{ display: isLessSm ? 'inline' : 'block' }}>
            {t('cashbackCalculator.subheading')}
          </Typography>
          <Typography variant="subtitle2">{t('cashbackCalculator.goodToKnow')}</Typography>
        </Box>
        <CalculatorBox>
          <DetailCalculatorWithUrlChange
            onFundSelect={onFundSelect}
            bookNowUrl={CASHBACK_WIZARD_START}
            calculatorRef={ref}
          />
        </CalculatorBox>
      </Wrapper>
    </Container>
  );
};
