import React from 'react';
import { styled } from '@mui/material/styles';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import InfoOutlinedIcon from '../../features/redesign/BankOverview/images/info_icon.svg';

const Container = styled('span')<{ variant?: 'info' | 'warn' }>(() => ({
  width: '32px',
  height: '32px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  verticalAlign: 'top',
}));

const InfoSmallIcon = styled(InfoOutlinedIcon)({
  width: '16px',
  height: '16px',
});

const ReportProblemSmallIcon = styled(ReportProblemOutlinedIcon)({
  width: '24px',
  height: '24px',
  color: '#D6580F',
});

export interface InfoIconProps {
  variant: 'info' | 'warn';
}

export const InfoIcon = React.forwardRef<HTMLDivElement, InfoIconProps>(
  ({ variant, ...rest }, ref) => (
    <Container {...rest} variant={variant} ref={ref}>
      {variant === 'info' && <InfoSmallIcon />}
      {variant === 'warn' && <ReportProblemSmallIcon />}
    </Container>
  )
);
