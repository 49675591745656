import React from 'react';
import Head from 'next/head';
import Script from 'next/script';
import { useTranslation } from 'react-i18next';
import { Layout } from '../components/layout/redesign/Layout';
import { useFAQ } from '../components/structuredData/useFAQ';
import { useWebPageGraph } from '../components/structuredData/useWepPageGraph';
import { Content } from '../features/redesign/Content';

export default function index() {
  const { t } = useTranslation();
  const faqLdJson = useFAQ();
  const webPageLdJson = useWebPageGraph(t('landingMain.title'), t('landingMain.description'));

  return (
    <Layout searchEnabled>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqLdJson),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(webPageLdJson),
          }}
        />
        <title>{t('landingMain.title')}</title>
        <meta name="language" content="deutsch, de" />
        <meta name="robots" content="noodp,index,follow" />

        <meta name="keywords" content={t('landingMain.keywords')} />
        <meta name="description" content={t('landingMain.description')} />
        <meta name="page-topic" content={t('landingMain.pageTopics')} />

        <meta name="facebook-domain-verification" content="pzm83662div8i33cyasjlo15rh5b15" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://rentablo.de" />
        <meta property="og:title" content={t('landingMain.title')} />
        <meta property="og:site_name" content={t('landingMain.title')} />
        <meta
          property="og:image"
          content="https://rentablo.de/images/sharing/rentablo-cashback.jpg"
        />
        <meta
          property="og:image"
          content="https://rentablo.de/images/sharing/rentablo-best-finance-manager.jpg"
        />
        <meta
          property="og:image"
          content="https://rentablo.de/images/sharing/rentablo-cashback-explanation.jpg"
        />
        <meta property="og:image" content="https://rentablo.de/images/rentablo-logo-white.svg" />
        <meta property="og:image" content="https://rentablo.de/images/rentablo-phone.jpg" />
        <meta property="og:image" content="https://rentablo.de/images/rentablo-mobile.jpg" />
        <meta property="og:description" content={t('landingMain.description')} />
      </Head>

      <Content />
      <Script
        type="text/javascript"
        data-skip-lazy=""
        src="https://www.provenexpert.com/widget/richsnippet.js?u=1HmA3Z3pkNUplqGB1xmpmEwZ0xmplHQp&v=3"
        async
      ></Script>
    </Layout>
  );
}
