import React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getCounterNumber } from '../../src/helpers/getCounterNumber';

const CardNumber = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(5.5),
  right: theme.spacing(4.75),
  width: '221px',
  borderRadius: '8px',
  backgroundColor: theme.palette.grey[800],
  padding: theme.spacing(4, 4, 3),

  [theme.breakpoints.down('sm')]: {
    width: 'auto',
  },
}));

const MainNumber = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '56px',
  lineHeight: '20px',
  fontWeight: '700',
  textTransform: 'uppercase',

  [theme.breakpoints.down('sm')]: {
    fontSize: '42px',
    lineHeight: '20px',
  },
}));

const FirstLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight: '600',
  textTransform: 'uppercase',
  marginBottom: theme.spacing(0.5),

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

const SecondLine = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
  fontSize: '18px',
  lineHeight: '20px',
  fontWeight: '600',
  textTransform: 'uppercase',

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '16px',
  },
}));

export const CashbackNumber = () => {
  const { t } = useTranslation();

  return (
    <CardNumber>
      <Box display="flex" alignItems="center" gap={2} mb={2}>
        <MainNumber>
          {new Intl.NumberFormat('de-DE', {
            notation: 'compact',
            compactDisplay: 'short',
            maximumFractionDigits: 0,
          })
            .format(getCounterNumber())
            .replace(/\sMio\./, '')}
        </MainNumber>
        <Box>
          <FirstLine>{t('redesignCashbackPage.fundDiscount.cashbackNumber.currency')}</FirstLine>
          <SecondLine>{t('redesignCashbackPage.fundDiscount.cashbackNumber.cashback')}</SecondLine>
        </Box>
      </Box>
      <Typography
        fontSize="12px"
        lineHeight="20px"
        color="common.N400"
        textTransform="uppercase"
        fontWeight={600}
        letterSpacing="0.04em"
      >
        {t('redesignCashbackPage.fundDiscount.cashbackNumber.title')}
      </Typography>
    </CardNumber>
  );
};
