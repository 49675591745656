import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  accordionSummaryClasses,
  Container,
  Grid,
  styled,
  useTheme,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Header } from '../../../components/Header/Header';

const ExpandMoreIconStyled = styled(ExpandMoreIcon)(({ theme }) => ({
  color: theme.palette.tertiary.main,
}));

const ContainerStyled = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(30),

  [theme.breakpoints.down('md')]: {
    paddingTop: theme.spacing(30),
  },

  [theme.breakpoints.down('sm')]: {
    paddingTop: theme.spacing(21.5),
  },
}));

const AccordionStyled = styled(Accordion)(({ theme }) => ({
  background: 'transparent',
  boxShadow: 'none',
  border: 'none',
  borderBottom: `1px solid ${theme.palette.border?.main}`,

  '&:before': {
    opacity: 0,
  },

  [`&.${accordionSummaryClasses.expanded}`]: {
    margin: 0,
  },
}));

const AccordionSummaryStyled = styled(AccordionSummary)(({ theme }) => ({
  padding: 0,

  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]: {
    marginTop: theme.spacing(4),
    transition:
      'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },

  [`& .${accordionSummaryClasses.content}`]: {
    margin: theme.spacing(6, 0),

    [`&.${accordionSummaryClasses.expanded}`]: {
      margin: theme.spacing(6, 0, 2, 0),

      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(5, 0, 2, 0),
      },
    },

    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(5, 0),
    },
  },
  [`&.${accordionSummaryClasses.expanded}`]: {
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
}));

const AccordionDetailsStyled = styled(AccordionDetails)(({ theme }) => ({
  padding: `0 0 ${theme.spacing(5)} 0`,
}));

export const FaqExpansionPanels = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const questions = t('redesignCashbackPage.faq.questions', {
    returnObjects: true,
  }) as {
    title: string;
    text: string;
    id: string;
  }[];

  return (
    <ContainerStyled data-sectionid="faq">
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8}>
          <Header
            variant="h2"
            headerText={t('redesignCashbackPage.faq.title')}
            subHeaderText={t('redesignCashbackPage.faq.subtitle')}
            sx={{
              marginBottom: theme.spacing(14),
              [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(8),
              },
            }}
            id="faq"
          />
          {questions.map((question) => (
            <AccordionStyled key={question.title} data-contentid={question.id}>
              <AccordionSummaryStyled expandIcon={<ExpandMoreIconStyled />}>
                <Header variant="h5" headerText={question.title} />
              </AccordionSummaryStyled>
              <AccordionDetailsStyled>
                <Typography
                  component="div"
                  color="textSecondary"
                  dangerouslySetInnerHTML={{ __html: question.text }}
                  sx={{
                    fontSize: '18px',
                    lineHeight: '28px',
                    fontWeight: 400,

                    [theme.breakpoints.down('sm')]: {
                      fontSize: '16px',
                      lineHeight: '24px',
                      fontWeight: 500,
                    },
                  }}
                />
              </AccordionDetailsStyled>
            </AccordionStyled>
          ))}
        </Grid>
      </Grid>
    </ContainerStyled>
  );
};
