import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useGoogleTagManagerViewHandler = (
  userFlowId: string,
  data?: Record<string, string | undefined | null>
) => {
  const router = useRouter();
  const [currentInView, setCurrentInView] = useState<string>('');
  const sendViewEvent = useCallback((entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setCurrentInView(entry.target.getAttribute('data-sectionId') as string);
      }
    });
  }, []);

  useEffect(() => {
    let inViewTimer: number;
    let highlyEngagedTimer: number;
    if (currentInView) {
      inViewTimer = (setTimeout as Window['setTimeout'])(() => {
        window.dataLayer?.push({
          userFlowId,
          workflowId: userFlowId,
          contextId: currentInView,
          pageRoute: router.pathname,
          event: 'in-view',
          ...data,
        });
      }, 2000);

      highlyEngagedTimer = (setTimeout as Window['setTimeout'])(() => {
        window.dataLayer?.push({
          userFlowId,
          workflowId: userFlowId,
          event: 'high-engagement',
          contextId: currentInView,
          pageRoute: router.pathname,
          ...data,
        });
      }, 15000);
    }

    return () => {
      clearTimeout(inViewTimer);
      clearTimeout(highlyEngagedTimer);
    };
  }, [currentInView]);

  useEffect(() => {
    // it is here because we have to be sure we already have Intersection Observer
    const observer = new IntersectionObserver(sendViewEvent, {
      root: null,
      rootMargin: '-50% 0% -50% 0%',
    });
    const elements = document.querySelectorAll('[data-sectionid]:not(header)');
    elements?.forEach((element) => observer.observe(element));
  }, []);
};
