import React from 'react';
import { Typography, Button, styled, Divider, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SIGNUP } from '../../src/helpers/urlConstants';
import { Header } from '../../components/Header/Header';

const ApplicationWrap = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridTemplateRows: 'minmax(139px, auto) minmax(327px, auto)',
  gap: '16px 16px',
  gridTemplateAreas: `"Application OneClick OneClick"
                      "Application Evaluate SetGoals"`,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '277px 300px 333px',
    gap: '16px 16px',
    gridTemplateAreas: `"Application Application"
                        "Evaluate OneClick"
                        "SetGoals SetGoals"`,
  },
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: '1fr',
    gridTemplateRows: '277px 300px 300px 333px',
    gap: '16px 16px',
    gridTemplateAreas: `"Application"
                        "OneClick"
                        "Evaluate"
                        "SetGoals"`,
  },
}));

const ApplicationLeft = styled('div')(({ theme }) => ({
  gridArea: 'Application',
  background: theme.palette.background.default,
  padding: theme.spacing(6, 7),
  borderRadius: '8px',
  backgroundSize: '997px 623px',
  backgroundImage: 'url("/images/newLanding/application-finance.png")',
  backgroundPosition: '28px 108px',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.up('lg')]: {
    // Note: First column cuts off part of Evaluate block
    minWidth: '489px',
  },

  [theme.breakpoints.down('md')]: {
    backgroundPosition: 'left 20px top 100px',
    padding: theme.spacing(4, 5),
  },
}));

const OneClick = styled('div')(({ theme }) => ({
  gridArea: 'OneClick',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(6, 7),
  background: theme.palette.background.default,
  borderRadius: '8px',
  backgroundSize: '274px 151px',
  backgroundImage: 'url("/images/newLanding/oneclick.png")',
  backgroundPosition: 'calc(100% + 32px) -20px',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(4, 5),
    backgroundPosition: '20px calc(100% - 24px)',
  },
}));

const OneClickText = styled('div')(({ theme }) => ({
  width: 'calc(100% - 220px)',
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Evaluate = styled('div')(({ theme }) => ({
  gridArea: 'Evaluate',
  display: 'flex',
  alignItems: 'flex-end',
  background: theme.palette.background.default,
  padding: theme.spacing(6, 7),
  borderRadius: '8px',
  backgroundSize: '488px 163px',
  backgroundImage: 'url("/images/newLanding/risks.png")',
  backgroundPosition: '28px 43px',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 5),
    backgroundPosition: '20px 30px',
  },
}));

const SetGoals = styled('div')(({ theme }) => ({
  gridArea: 'SetGoals',
  display: 'flex',
  alignItems: 'flex-end',
  background: theme.palette.background.default,
  padding: theme.spacing(6, 7),
  borderRadius: '8px',
  backgroundSize: '388px 215px',
  backgroundImage: 'url("/images/newLanding/plan-wealth.png")',
  backgroundPosition: '28px 14px',
  backgroundRepeat: 'no-repeat',

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 5),
    backgroundPosition: '20px 14px',
  },
}));

const CardCaption = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  textTransform: 'uppercase',
  fontSize: '12px',
  lineHeight: '28px',
  fontWeight: 600,
  letterSpacing: '0.08em',
}));

const CardTitle = styled(Typography)``;

const BottomDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(12, 0, 6),
  background: 'rgba(228, 233, 241, 0.24)',
}));

const BottomContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(5),
    textAlign: 'center',
  },
}));

const BottomMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[200],
}));

export const OneForAll = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div>
      <Header
        variant="h2"
        headerText={t(`redesignMainPage.preview.application.header.title`)}
        subHeaderText={t(`redesignMainPage.preview.application.header.title2`)}
        id="depot-management-app"
        sx={{
          marginBottom: theme.spacing(11),
        }}
      />

      <ApplicationWrap>
        <ApplicationLeft>
          <div>
            <CardCaption variant="subtitle2" style={{ color: '#1182A6' }}>
              {t(`redesignMainPage.preview.application.finance.subtitle`)}
            </CardCaption>
            <CardTitle variant="h6" color="textPrimary">
              {t(`redesignMainPage.preview.application.finance.title`)}
            </CardTitle>
          </div>
        </ApplicationLeft>

        <OneClick>
          <OneClickText>
            <CardCaption variant="subtitle2" style={{ color: '#00997D' }}>
              {t(`redesignMainPage.preview.application.oneClick.subtitle`)}
            </CardCaption>
            <CardTitle variant="h6" color="textPrimary">
              {t(`redesignMainPage.preview.application.oneClick.title`)}
            </CardTitle>
          </OneClickText>
        </OneClick>

        <Evaluate>
          <div>
            <CardCaption variant="subtitle2" style={{ color: '#FF8139' }}>
              {t(`redesignMainPage.preview.application.evaluate.subtitle`)}
            </CardCaption>
            <CardTitle variant="h6" color="textPrimary">
              {t(`redesignMainPage.preview.application.evaluate.title`)}
            </CardTitle>
          </div>
        </Evaluate>

        <SetGoals>
          <div>
            <CardCaption variant="subtitle2" style={{ color: '#5F5BB4' }}>
              {t(`redesignMainPage.preview.application.setGoals.subtitle`)}
            </CardCaption>
            <CardTitle variant="h6" color="textPrimary">
              {t(`redesignMainPage.preview.application.setGoals.title`)}
            </CardTitle>
          </div>
        </SetGoals>
      </ApplicationWrap>

      <BottomDivider />

      <BottomContainer>
        <BottomMessage variant="subtitle2">
          {t(`redesignMainPage.preview.application.moreFinancial.text`)}
        </BottomMessage>
        <Button
          href={SIGNUP}
          variant="contained"
          color="tertiary"
          size="small"
          data-eventid="test-finance-manager-now"
          sx={{
            padding: theme.spacing(3, 4),
            [theme.breakpoints.down('sm')]: { width: '100%' },
          }}
        >
          {t(`redesignMainPage.preview.application.moreFinancial.btn`)}
        </Button>
      </BottomContainer>
    </div>
  );
};
