import { Box, Container as MuiContainer, styled, useTheme, Grid } from '@mui/material';
import React from 'react';
import dynamic from 'next/dynamic';
import { useTranslation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { TwoSectionsContainer } from '../../components/TwoSectionsContainer/TwoSectionsContainer';
import { ButtonWithLink } from '../../components/ButtonWithLink/ButtonWithLink';
import { CASHBACK_CALCULATOR, CASHBACK_WIZARD_START } from '../../src/helpers/urlConstants';
import { CheckmarkList } from '../../components/CheckmarkList/CheckmarkList';
import { ISINSearch } from '../../components/ISINSearch/ISINSearch';
import { Header } from '../../components/Header/Header';

const Video = dynamic(() => import('../../components/Video/Video'), { ssr: false });

const Container = styled(Box)`
  position: relative;
  padding-top: 65px;
  height: 100vh;
  background-image: url('/images/background.svg');
  background-repeat: no-repeat;
  background-position: calc(50% + 400px) 100px;
  background-size: contain;

  @media (min-height: 1100px) {
    height: auto;
    padding: ${({ theme }) => theme.spacing(75, 0)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: 58px;
    min-height: 100vh;
    height: auto;
    background-position: 50% 400px;
    background-size: auto;
    padding-bottom: ${({ theme }) => theme.spacing(33)};
  }
`;

const VideoContainer = styled(Grid)`
  min-height: 338px;
  height: 1px;
`;

const InfoContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(23)};
    padding-top: ${({ theme }) => theme.spacing(20)};
  }
`;

const SearchContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacing(3, 5)};
  background: white;
  display: none;

  ${({ theme }) => theme.breakpoints.down('md')} {
    display: block;
    padding: ${({ theme }) => theme.spacing(3, 0)};
  }}
`;

export const MainScreenWithVideo = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Container data-sectionid="main-screen-with-video">
      <MuiContainer sx={{ height: '100%' }}>
        <SearchContainer>
          <ISINSearch />
        </SearchContainer>
        <Box display="flex" alignItems="center" height="100%">
          <TwoSectionsContainer>
            <InfoContainer>
              <Header
                headerText={t(`landingMain.mainScreen.title.first`)}
                subHeaderText={t(`landingMain.mainScreen.title.second`)}
                variant="h1"
                sx={{ marginBottom: theme.spacing(3) }}
              />
              <CheckmarkList
                list={[
                  t('landingMain.checkmarksList.first'),
                  t('landingMain.checkmarksList.second'),
                  t('landingMain.checkmarksList.third'),
                ]}
              />
              <ButtonWithLink
                data-eventid="book-cashback-button"
                buttonHref={CASHBACK_WIZARD_START}
                buttonText={t(`landingMain.mainScreen.startNow`)}
                linkHref={() =>
                  document
                    .querySelector(CASHBACK_CALCULATOR)
                    ?.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
                linkText={t(`landingMain.mainScreen.learnMore`)}
              />
            </InfoContainer>
            <LazyLoad>
              <VideoContainer>
                <Video
                  url="https://www.youtube.com/embed/XASmSGUN2EI"
                  style={{ borderRadius: '8px', overflow: 'hidden' }}
                />
              </VideoContainer>
            </LazyLoad>
          </TwoSectionsContainer>
        </Box>
      </MuiContainer>
    </Container>
  );
};
